body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #555;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

.hamburger{
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.25s;
  position: relative;
}
.hamburger-top, .hamburger-middle, .hamburger-bottom{
  position: absolute;
  top: 0%;
  left: 0%;
  width: 24px;
  height: 2px;
  background-color: #000;
  transform: rotate(0);
  transition: all .5s;
}
.hamburger-middle{
  transform: translateY(7px);
}
.hamburger-bottom{
  transform: translateY(14px);
}
.open{
  transform: rotate(90deg);
  transform: translateY(0px);
}
.open .hamburger-top{
  transform: rotate(45deg) translateY(6px) translate(6px);
}
.open .hamburger-middle{
  display: none;
}
.open .hamburger-bottom{
  transform: rotate(-45deg) translateY(6px) translate(-6px);
}